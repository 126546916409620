<template>
  <div>
    <div class="dashboard-project-details pt-5">
      <section class="row">
        <div class="col-md-12 projects-breadcrumb">
          <router-link to="/projects" class="mr-4">Projects</router-link>
          <span class="mr-4 projects-breadcrumb-arrow">></span>
          <router-link :to="'/projects/' + jobDetails.ProjectId">{{
            jobDetails.projectName
          }}</router-link>
          <span class="mx-4 projects-breadcrumb-arrow">></span>
          <router-link to="">{{ jobDetails.id }}</router-link>
        </div>
      </section>
      <section class="row">
        <div class="col-lg-12 d-flex">
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Status</p>
            </div>
            <div class="project-details-item-value">
              {{ jobDetails.status }}
            </div>
          </div>
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Submitted by</p>
            </div>
            <div
              v-if="submittedBy"
              class="project-details-item-value user-panel"
            >
              <img
                class="mr-2"
                :src="submittedBy.avatar"
                alt="Profile picture"
              />
              {{ submittedBy.firstName }}
            </div>
          </div>
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Collaborators</p>
            </div>
            <div
              class="project-details-item-value user-panel collaborators-panel"
            >
              <template v-for="item in jobDetails.collaborators">
                <img :src="item.avatar" alt="Profile picture" :key="item.id" />
              </template>
            </div>
          </div>
          <div class="project-details-item">
            <div class="project-details-item-key">
              <p>Created at</p>
            </div>
            <div class="project-details-item-value">
              {{
                jobDetails.createdAt &&
                $utilFunc.convertTime(jobDetails.createdAt)
              }}
            </div>
          </div>
        </div>
      </section>
      <section class="row project-thumbnail">
        <div class="col-sm-12 col-xl-10 overflow-hidden mb-0">
          <div :id="this.$route.params.jobId"></div>
        </div>
      </section>
      <section class="row project-thumbnail">
        <div v-if="showError">
          <p>Unfortunately, an error acquired during simulation.</p>
          <p>The error message is:</p>
          <pre>{{ result ? result.error : undefined }}</pre>
        </div>
        <results-area v-else-if="showResult" :result="result" />
        <div v-else>
          <p>The job is still running!</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { GET_JOB_DETAILS_BY_ID } from '@/store/actions.type'
import { CircuitViewer } from '@budapest-quantum-computing-group/piquasso-composer/src/index'
import ResultsArea from '@/components/resultsarea/results-area'

export default {
  name: 'DashboardProjectDetails',
  components: {
    ResultsArea,
  },
  data() {
    return {
      jobDetails: {},
    }
  },
  async mounted() {
    this.jobDetails = await this.$store.dispatch(
      GET_JOB_DETAILS_BY_ID,
      this.$route.params.jobId
    )
    if (!this.jobDetails.result.error) {
      this.jobDetails.result = this.$utilFunc.preprocessResult(
        this.jobDetails.result
      )
    }
    CircuitViewer.fromJson(
      this.jobDetails.projectData,
      this.$route.params.jobId
    )
  },
  computed: {
    submittedBy() {
      if (this.jobDetails.collaborators) {
        return this.jobDetails.collaborators.filter(
          (collaborator) => this.jobDetails.UserId === collaborator.id
        )[0]
      } else {
        return undefined
      }
    },
    result: function () {
      return this.jobDetails?.result
    },
    showResult: function () {
      return !!this.jobDetails.result
    },
    showError: function () {
      return !!this.jobDetails.result?.error
    },
  },
}
</script>
